import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import moment from "moment-timezone";
import {
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerSelectTag,
  dataLayerInputTag,
} from "data-layer-test-oo-gtm";
import "@lottiefiles/lottie-player";
moment.tz.setDefault("America/Phoenix");

import Toasted from "vue-toasted";

Vue.use(Toasted);

Vue.directive(
  dataLayerAnchorTag,
  dataLayerButtonTag,
  dataLayerInputTag,
  dataLayerSelectTag
);

import VueMask from "v-mask";
Vue.use(VueMask);

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

import "./assets/scss/main.scss";

import VueCarousel from "vue-carousel";

Vue.use(VueCarousel);

const { default: VueGtm } = require("vue-gtm");

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.use(VueGtm, {
  id: ["GTM-T6RNSMQ"],
  enabled: true,
  debug: true,
  vueRouter: router,
});

import Carousel3d from "vue-carousel-3d";

Vue.use(Carousel3d);

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-vue";

import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  API: {
    endpoints: [
      {
        name: "asulink",
        endpoint: process.env.VUE_APP_ASULINK_API_URL.replace(
          "site_id",
          store.state.selected_site_id
        ),
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "x-api-key":
              store.state.selected_site_id == "ASU00002"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU02
                : store.state.selected_site_id == "ASU00003"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU03
                : store.state.selected_site_id == "ASU00004"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU04
                : process.env.VUE_APP_ASULINK_API_KEY,
          };
        },
      },
      {
        name: "proxy",
        endpoint: `${process.env.VUE_APP_PROXY_API_URL}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "x-api-key":
              store.state.selected_site_id == "ASU00002"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU02
                : store.state.selected_site_id == "ASU00003"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU03
                : store.state.selected_site_id == "ASU00004"
                ? process.env.VUE_APP_ASULINK_API_KEY_ASU04
                : process.env.VUE_APP_ASULINK_API_KEY,
          };
        },
      },
    ],
  },
});

/**
 * Calling Auth.configure, not Amplify.configure to bypass bug
 * @see https: //github.com/aws-amplify/amplify-js/issues/6330#issuecomment-733433604
 */
Auth.configure({
  region: process.env.VUE_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID,
  userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  mandatorySignIn: true,
  oauth: {
    domain: process.env.VUE_APP_AWS_USER_POOLS_DOMAIN,
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.VUE_APP_SIGN_IN_URL,
    redirectSignOut: process.env.VUE_APP_SIGN_OUT_URL,
    responseType: "code",
  },
});
/**
 * Configure API clients
 */

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
